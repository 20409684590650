import React, {Component} from "react"
import styles from "./Header.module.css"
import tspaNampaLogo from "./parts/img/tspanampa-logo.png"
import {ReactComponent as SignatureIcon} from "./parts/img/signature.svg"

interface Props {}

export class Header extends Component<Props, {}> {
    public render() {
        return (
            <header className={styles.root}>
                <div className={styles.wrapper}>
                    <h1 className={styles.h1}>
                        <img src={tspaNampaLogo} alt="tspanampa-logo" />
                        <div className={styles.tspaLetterSection}>
                            <span>
                                <strong>(tspa)</strong>
                            </span>
                        </div>
                    </h1>
                    <div className={styles.rightWrapper}>
                        <SignatureIcon className={styles.signatureIcon} />
                        <span className={styles.text}>APPLY TO TSPA</span>
                    </div>
                </div>
            </header>
        )
    }
}
